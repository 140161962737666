import { Task } from "./Task"

export const Tasks = ({ tasks, onDelete, onToggle }) => {
  return (
    //empty fragment
    <>
      {
        tasks.map((task) => (
          <Task
            key={task.id}
            task={task}
            onDelete={onDelete}
            onToggle={onToggle}
          />
        ))
      }
    </>
  )
}
