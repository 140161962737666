// import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header } from './components/Header';
import { Tasks } from './components/Tasks';
import { useState, useEffect } from "react";
import { AddTask } from './components/AddTask';
import { Footer } from './components/Footer';
import About from './components/About';

function App() {
  const [showAddTask, setShowAddTask] = useState(false);
  const [tasks, setTasks] = useState([]);
  useEffect(
    () => {
      const getTasks = async () => {
        const tasksFromServer = await fetchTasks();
        setTasks(tasksFromServer);
      }

      getTasks();
    }
    , []);

  // Fetch Tasks
  const fetchTasks = async () => {
    console.log('fetchTasks');
    const res = await fetch('http://localhost:5000/tasks');
    const data = await res.json();

    console.log(data);
    return data;
  }

  // Fetch Task
  const fetchTask = async (id) => {
    const res = await fetch(`http://localhost:5000/tasks/${id}`);
    const data = await res.json();

    console.log(data);
    return data;
  }

  // Add Task
  const addTask = async (task) => {
    console.log('addTask', task);
    const res = await fetch('http://localhost:5000/tasks',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(task)
      });

    const data = await res.json();
    setTasks([...tasks, data]);

    // const id = Math.floor(Math.random() * 10000) + 1;
    // const newtask = { id, ...task };
    // setTasks([ ...tasks, newtask]);
  };

  // Delete task
  const deleteTask = async (id) => {
    console.log('deleteTask', id);
    const res = await fetch(`http://localhost:5000/tasks/${id}`, { method: 'DELETE' });
    // setTasks(tasks.filter((task) => task.id !== id));

    //We should control the response status to decide if we will change the state or not.
    res.status === 200 ?
      setTasks(tasks.filter((task) => task.id !== id))
      : alert('Error Deleting This Task')
  };

  // Toggle Reminder
  const toggleReminder = async (id) => {
    console.log('toggleReminder', id);

    const taskToToggle = await fetchTask(id);
    const updTask = {
      ...taskToToggle,
      reminder: !taskToToggle.reminder
    };

    const res = await fetch(`http://localhost:5000/tasks/${id}`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(updTask)
      }
    )

    const data = await res.json();

    setTasks(
      tasks.map((task) => task.id === id ? {
        ...task, reminder: data.reminder
      } : task)
    );
  };

  return (
    <Router>
      <div className="container">
        <Header
          onAdd={() => setShowAddTask(!showAddTask)}
          showAdd={showAddTask}
        />
        <Routes>
          <Route
            path='/'
            element={
              <>
                {showAddTask && <AddTask onAdd={addTask} />}
                {
                  tasks.length > 0 ?
                    <Tasks tasks={tasks}
                      onDelete={deleteTask}
                      onToggle={toggleReminder}
                    />
                    : 'No Tasks'
                }
              </>
            }
          />
          <Route path='/about' element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
