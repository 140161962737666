// import React from 'react'
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Button } from './Button';

// destructured props {}, it can be props.title , props.onAdd , props.showAdd
export const Header = ({ title, onAdd, showAdd }) => {
  const location = useLocation();
  return (
    <header className='header'>
      <h1>{title}</h1>
      {
        location.pathname === '/' &&
        <Button
          color={showAdd ? 'red' : 'green'}
          text={showAdd ? 'Close' : 'Add'}
          onClick={onAdd}
        />
      }
    </header>
  )
}

Header.defaultProps = {
  title: 'Task Tracker 1',
}

Header.propTypes = {
  title: PropTypes.string,
}

// CSS in JS
// const headingStyle = {
//     color: 'red',
//     backgroundColor: 'black',
// }